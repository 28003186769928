<template>
    <registry-table
      ref="table"
      v-loading="loading"
      v-show="!isHidden"
      @expand="expand"
      @edit-record="editRecord"
      @add-record="addRecord"
      @query-search="querySearch"
      @grid-ready="isGridReady = true"
      @loaded="setRowCount"
      :CSS="CSS"
      :CSSClasses="CSSClasses"
      :key="key"
      :registry-id="registryId"
      :guid="guid"
      :custom-columns="columns"
      :external-filters="dataFilters"
      :filtersForFilterCmp="filters"
      :loading="loading"
      :sideBar="sideBar"
      :closeToolPanel="closeToolPanel"
      :floatingFilter="floatingFilter"
      :row-double-clicked="rowDoubleClicked"
      :check-box-selection="checkBoxSelection"
      :header-check-box-selection="headerCheckBoxSelection"
      :is-pivot-mode="isPivotMode"
      :cssQuickSearch="cssQuickSearch"
      :show-button-ag-grid="visibleGridButtons"
      :placeholderQuickSearch="placeholderQuickSearch"
      :groupUseEntireRow="groupUseEntireRow"
      :show-count="showCount"
      :disabled-column-header="disabledColumnHeader"
      :hide-header="hideHeader"
      :wrap-header="wrapHeader"
      :state="editorRemote"
      :optimize-options="optimizeOptions.value"
      :readonly="_isReadonly"
      :theme="theme"
      :is-fast-card="isFastCard"
      :hide-export="hideExport"
      :rowClassRules="rowClassRules"
      :page-size="pageSize"
      :cache-block-size="cacheBlockSize"
      :rowHeight="rowHeight"
      :suppressGroupRowsSticky="suppressGroupRowsSticky"
      :defaults="defaults"
      :data-type="'registry'"
      :data-source-id="registryId"
      :data-attribute="name || null"
      :data-alias="editorAlias || null"
    >
      <slot>
        <div>
          <el-button v-show="isEditor()" :type="isSaveFilter ? 'info' : 'warning'" size="small"
          style="position: absolute; bottom: 10px; right: 350px;z-index: 2;" @click="saveFilterModelAndStateTable">{{$locale.main.button.save_filter}}</el-button>
        </div>
      </slot>
    </registry-table>
</template>

<script>
import mixin from '@/components/InterfaceEditor/components/mixins'
import VisibleMixin from '@/components/InterfaceEditor/components/visible_properties_mixin'
import RegistryTable from '@/services/RegistryTable/index.vue'
import RegistryCard from '@/components/RegistryCard/index.vue'
import FilterBuilder, { EComponentTypes } from '../utils'

export default {
  name: 'registry',
  components: { RegistryTable, RegistryCard },
  inject: {
    isEditor: { default: () => false },
    openRegistryCard: { default: () => {} },
    forceUpdateSettingsPanel: { default: () => () => {} },
    getContainersStore: {
      default: () => () => {}
    },
    getInterfaceWrapper: {
      default: () => () => {}
    },
    getInterfaceEditorVersion: {
      default: () => () => 1
    }
  },
  mixins: [mixin, VisibleMixin],
  props: {
    filterModel: {
      type: [Object, Array],
      frozen: true
    },
    guid: {
      type: String,
      frozen: true
    },
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    editorAlias: {
      type: String,
      description: 'alias'
    },
    registryId: {
      type: Number,
      description: 'registry_id',
      editor: 'RegistrySelect'
    },
    // столбцы от редактора столбцов
    columns: {
      type: Array,
      description: 'columns',
      default: () => [],
      editor: 'TableColumns',
      options: {
        sourceType: 'registry'
      }
    },
    optimizeOptions: {
      type: Object,
      default: () => ({}),
      editor: 'OptimizeColumnsLoad'
    },
    sideBar: {
      type: Boolean,
      description: 'side_bar'
    },
    closeToolPanel: {
      type: Boolean,
      description: 'close_tool_panel',
      default: true
    },
    checkBoxSelection: {
      type: Boolean,
      description: 'check_box_selection',
      default: false
    },
    headerCheckBoxSelection: {
      type: Boolean,
      description: 'header_check_box_selection',
      default: false
    },
    floatingFilter: {
      type: Boolean,
      description: 'floating_filter'
    },
    rowDoubleClicked: {
      type: Boolean,
      description: 'row_double_clicked'
    },
    groupUseEntireRow: {
      type: Boolean,
      description: 'group_use_entire_row'
    },
    showCount: {
      type: Boolean,
      description: 'show_count'
    },
    disabledColumnHeader: {
      type: Boolean,
      default: false,
      description: 'disabled_column_header'
    },
    hideHeader: {
      type: Boolean,
      default: false,
      description: 'hide_header'
    },
    suppressGroupRowsSticky: {
      type: Boolean,
      default: true,
      description: 'suppress_group_rows_sticky'
    },
    wrapHeader: {
      type: Boolean,
      default: false,
      description: 'wrap_header'
    },
    hideExport: {
      type: Boolean,
      description: 'hide_export',
      default: false
    },
    alwaysActive: {
      type: Boolean,
      description: 'always_active'
    },
    rowClassRules: {
      type: Array,
      default: function () {
        return []
      },
      editor: 'RowClassRules'
    },
    autoRefresh: {
      type: [Object, Array],
      editor: 'AutoRefresh',
      default: function () {
        return {}
      }
    },
    pageSize: {
      type: Number,
      description: 'page_size',
      default: 100,
      options: {
        tooltip: {
          show: true,
          content: `default_100`
        }
      }
    },
    cacheBlockSize: {
      type: Number,
      description: 'cache_block_size',
      default: 50,
      options: {
        tooltip: {
          show: true,
          content: 'cache_block_size_dangder'
        }
      }
    },
    rowHeight: {
      type: Number,
      description: 'row_height',
      options: {
        tooltip: {
          show: true,
          content: 'row_height_des'
        }
      }
    },
    showButtonAgGrid: {
      type: Object,
      frozen: true
    },
    visibleToolbarButtons: {
      type: Object,
      default: function () {
        return {
          update: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          },
          add: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          },
          edit: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          },
          delete: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          },
          search: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          },
          export: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          },
          expand: {
            isHidden: {
              type: 'always',
              condition_type: 'and',
              conditions: []
            }
          },
          clearFilters: {
            isHidden: {
              type: 'never',
              condition_type: 'and',
              conditions: []
            }
          }
        }
      },
      editor: 'ShowButtonAgGrid'
    },
    cssQuickSearch: {
      type: String,
      description: 'css_quick_search',
      default: 'width: 30%; margin-left:50%;'
    },
    placeholderQuickSearch: {
      type: String,
      description: 'placeholder_quick_search',
      default: 'Поиск'
    },
    searchAttributes: {
      type: String,
      description: 'search_attributes',
      options: {
        tooltip: {
          show: true,
          content: `search_attributes_des`
        }
      }
    },
    searchOrByAnd: {
      editor: 'Select',
      description: 'search_or_by_and',
      default: 'and',
      options: {
        tooltip: {
          show: true,
          content: `Связка И: Ищется точное совпадение. Например "Акцент 2". Найдет "Акцент 2" <br>
                    Связка ИЛИ: Ищется фраза. Например "Акцент 2". Найдет "Акцент-платформа 2.0"`
        },
        multiple: false,
        options: [
          { name: 'И', id: 'and' },
          { name: 'ИЛИ', id: 'or' }
        ]
      }
    },
    theme: {
      editor: 'Select',
      description: 'theme_table',
      default: 'ag-theme-alpine',
      options: {
        multiple: false,
        options: [
          { name: 'Цвет: светлый; Высота: с отступами', id: 'ag-theme-alpine' },
          { name: 'Цвет: светлый; Высота: минимальная', id: 'ag-theme-balham' },
          { name: 'Цвет: темный; Высота: с отступами', id: 'ag-theme-alpine-dark' },
          { name: 'Цвет: темный; Высота: минимальная', id: 'ag-theme-balham-dark' }
        ]
      }
    },
    editorRemote: {
      type: Object,
      editor: 'RemoteList',
      description: 'state',
      options: {
        multiple: false,
        clearable: true
      },
      default: () => {
        return {
          stateId: null
        }
      }
    },
    defaultCardId: {
      type: [Object, Number],
      description: 'card',
      editor: 'Cards',
      default: () => {
        return {
          cardId: null,
          isWindow: false,
          windowTitle: '',
          windowWidth: '25',
          containerAlias: null
        }
      }
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      },
      default () {
        return []
      }
    },
    defaults: {
      type: Array,
      default: () => {
        return []
      },
      editor: 'Filters',
      options: {
        title: 'По умолчанию',
        showXrefOption: false,
        showEqualsTypes: false
      }
    },
    initialColumnState: {
      type: Array,
      frozen: true
    },
    isPivotMode: {
      type: Boolean,
      default: false,
      frozen: true
    },
    // из configurator открытие модального окна, чтобы сохранить вид таблицы (columnState)
    openModalWindowByConfigurator: {
      type: Boolean,
      frozen: true
    },
    isFastCard: {
      type: Boolean,
      description: 'is_fast_card',
      options: {
        tooltip: {
          show: true,
          content: 'is_fast_card_des'
        }
      },
      default: () => {
        return false
      }
    }
  },
  data () {
    return {
      isExpanded: false,
      expandedSettings: {
        notExpandedStyle: null,
        containers: [],
        dashboard: { dom: null, defaultWidth: null },
        tabSelectionHeight: 0,
        tab: null,
        vueDraggableBlocks: [],
        v2: {}
      },
      loading: false,
      isGridReady: false,
      isComponentReady: false,
      isSaveFilter: false,
      columnFilter: null,
      searchModel: null,
      saveStateColumn: null,
      saveLastCount: 0,
      componentDestroyed: false
    }
  },
  watch: {
    key () {
      this.isGridReady = false
      this.isComponentReady = false
      this.$emit('change-property', { name: 'initialColumnState', value: [] })
    },
    isGridReady (value) {
      // console.log('%c%s', 'color: skyblue;', 'Registry isGridReady', value)
      if (value && this.initialColumnState) {
        // Внешнее состояние таблицы
        this.$refs.table.$refs.tableBody.$refs.grid.columnApi.applyColumnState({ state: this.initialColumnState, applyOrder: true })
        this.isComponentReady = true
      } else if (value && (this.initialColumnState || []).length === 0) {
        this.isComponentReady = true
      }
      if (this.filterModel && Object.keys(this.filterModel).length) {
        this.$refs.table.$refs.tableBody.$refs.grid.gridApi.setFilterModel(this.filterModel)
      }
      if (this.isComponentReady && this.rowClassRules?.length) {
        setTimeout(() => {
          console.log('redrawRows')
          this.$refs.table.$refs.tableBody.$refs.grid.gridApi.redrawRows()
        }, 0)
      }
    },
    columnFilter (value) {
      if (this.isEditor() && this.isComponentReady) {
        this.$emit('change-property', { name: 'filterModel', value })
      }
    },
    columns () {
      this.addOptimizeAttribute()
    },
    optimizeOptions: {
      deep: true,
      handler () {
        this.addOptimizeAttribute()
      }
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  methods: {
    expand () {
      if (this.isEditor()) {
        return
      }
      this.isExpanded = !this.isExpanded
      if (this.expandedSettings.notExpandedStyle === null) {
        let tab = false
        let parent = this
        let tabSelectionHeight = 0
        let prev = null
        let previewBlocksUids = []
        while (parent !== undefined) {
          switch (parent.$options['_componentTag']) {
            case 'responsive-previewer':
              this.expandedSettings.vueDraggableBlocks = this.getHideBlocks(parent.$children[0], previewBlocksUids).map(
                (item) => {
                  return {
                    component: item,
                    isHiddenByDefault: item.$options.propsData.block.isHidden
                  }
                }
              )
              break
            // сохраняем uid блока с картой
            case 'preview-block':
              previewBlocksUids.push(parent._uid)
              break
            case 'grid-item':
              this.expandedSettings.containers.push({
                container: parent,
                defaultStyleZIndex: parent.style.zIndex,
                defaultStyleTransform: parent.style.transform,
                defaultStyleTop: parent.style.top,
                defaultStyleLeft: parent.style.left
              })
              break
            case 'Dashboard':
              this.expandedSettings.dashboard.dom = parent.$el.querySelector('.dashboard_form')
              if (this.expandedSettings.dashboard.dom !== null) {
                this.expandedSettings.dashboard.defaultWidth = this.expandedSettings.dashboard.dom.style.width
              }
              break
            // панель вкладки
            case 'el-tab-pane':
              if (tab) {
                tabSelectionHeight += tab.$el.offsetParent.offsetTop
              }
              tab = parent
              break
            // фрейм
            case 'dashboard':
              tabSelectionHeight = 0
              tab = false
              break
          }
          if (this.getInterfaceEditorVersion() === 2) {
            if (parent.$options['_componentTag'] === 'InterfaceViewerV2') {
              this.expandedSettings.v2.interfaceViewer = parent
              this.expandedSettings.v2.container = parent.$el.querySelector('.container')
              this.expandedSettings.v2.defaultContainerCssText = this.expandedSettings.v2.container.style.cssText
            }
          }
          prev = parent
          parent = parent.$parent
        }
        this.expandedSettings.tab = tab
        this.expandedSettings.tabSelectionHeight = tabSelectionHeight
        this.expandedSettings.notExpandedStyle = this.$refs['table'].$el
      }
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(entities => {
          this.resizeMap()
        })
      }
      if (this.isExpanded) {
        this.resizeObserver.observe(this.expandedSettings.tab.$el)
      } else {
        this.resizeObserver.unobserve(this.expandedSettings.tab.$el)
      }
      this.resizeMap()
    },
    getHideBlocks (block, uids) {
      let result = []
      // рассматриваем только блоки просмотра
      if (block.$options._componentTag !== 'preview-block') {
        return result
      }
      const prevResult = []
      const childrenBlocks = block.$children.reduce((accumulator, container) => {
        return accumulator + (container.$options._componentTag === 'preview-block' ? 1 : 0)
      }, 0)
      // запускаем эту же функцию для всех дочерних блоков
      if (childrenBlocks > 0) {
        block.$children.forEach((component) => {
          prevResult.push(...this.getHideBlocks(component, uids))
        }, this)
      }
      result.push(...prevResult)
      if (!uids.includes(block._uid)) {
        result.push(block)
      }
      return result
    },
    resizeMap () {
      let mapContainer = this.$refs['table'].$el
      // get tab and tab containers
      let top = this.expandedSettings.tab.$el.offsetParent.offsetTop + this.expandedSettings.tabSelectionHeight
      let left = this.expandedSettings.tab.$el.offsetParent.offsetLeft
      let height = this.expandedSettings.tab.$el.clientHeight - this.expandedSettings.tabSelectionHeight
      let width = this.expandedSettings.tab.$el.clientWidth
      if (this.isExpanded) {
        this.expandedSettings.containers.forEach((item) => {
          item.container.style.zIndex = '1'
          item.container.style.transform = ''
          item.container.useCssTransforms = false
        })
        // map container changes
        mapContainer.style.position = 'fixed'
        mapContainer.style.width = (width - 20) + 'px'
        mapContainer.style.height = (height - 20) + 'px'
        mapContainer.style.zIndex = '999'
        mapContainer.style.top = (top + 10) + 'px'
        mapContainer.style.left = (left + 10) + 'px'
        mapContainer.style.transform = ''
        if (this.expandedSettings.dashboard.dom !== null) {
          this.expandedSettings.dashboard.dom.style.width = '0'
        }
        if (this.getInterfaceEditorVersion() === 2) {
          this.getCard().hideButtonsBlock()
          if (this.expandedSettings.v2.container) {
            this.expandedSettings.v2.container.style.cssText = 'width: 1px;'
          }
        }
        this.expandedSettings.vueDraggableBlocks.forEach((item) => {
          item.component.$options.propsData.block.isHidden = true
        })
      } else {
        mapContainer.style = this.expandedSettings.notExpandedStyle
        this.expandedSettings.containers.forEach((item) => {
          item.container.style.left = item.defaultStyleLeft
          item.container.style.top = item.defaultStyleTop
          item.container.style.zIndex = item.defaultStyleZIndex
          item.container.style.transform = item.defaultStyleTransform
          item.container.useCssTransforms = true
        })
        if (this.expandedSettings.dashboard.dom !== null) {
          this.expandedSettings.dashboard.dom.style.width = this.expandedSettings.dashboard.defaultWidth
        }
        if (this.getInterfaceEditorVersion() === 2) {
          this.getCard().showButtonsBlock()
          if (this.expandedSettings.v2.container) {
            this.expandedSettings.v2.container.style.cssText =
              this.expandedSettings.v2.defaultContainerCssText
          }
        }
        this.expandedSettings.vueDraggableBlocks.forEach((item) => {
          item.component.$options.propsData.block.isHidden = item.isHiddenByDefault
        })
      }
    },
    setRowCount (value) {
      let rowCount = value.api.getDisplayedRowCount()
      this.$set(this.getModel(), this.name, rowCount === 0 ? null : rowCount)
    },
    /**
     * Вернёт фильтры в формате ApiQL
     *
     * @return {*[]|*}
     */
    getPayload () {
      if (typeof this.$refs.table.getPayload !== 'undefined') {
        return this.$refs.table.getPayload()
      }

      return null
    },
    /**
     * Вернёт фильтры, которые есть в настройках таблицы
     *
     * @return {Array.<Object>}
     */
    getFilterProps () {
      return this.filters
    },
    /**
     * Вернёт выделенные строки в таблце
     *
     * @return {Array.<Object>}
     */
    getSelectedRows () {
      if (typeof this.$refs.table.$refs.tableBody.$refs.grid !== 'undefined') {
        return this.$refs.table.selectedRows
      }
      return []
    },
    addOptimizeAttribute () {
      if (this.optimizeOptions.showOptimizeOptions === true) {
        let optimizeAttrs = ''
        if (typeof this.columns !== 'undefined') {
          this.columns.forEach((item) => {
            switch (item.columnType) {
              case 'htmlField':
                if (item.hasOwnProperty('htmlTemplate')) {
                  optimizeAttrs += item.htmlTemplate.match(/attr_[0-9]+_/gi) + ','
                }
                break
              case 'fieldGroup':
                item.children.forEach((children) => {
                  optimizeAttrs += this.fieldGroupAttrsParse(children)
                })
                break
              default:
                if (item.field.match(/attr_[0-9]+_/gi) !== null) {
                  optimizeAttrs += item.field + ','
                }
            }

            if (item.clickType === 'open_card') {
              let cardAttr = item.card.constantRecordId ? item.card.constantRecordId : item.card.fieldId
              optimizeAttrs += `attr_${cardAttr}_,`
            }
          })
          this.$set(this.optimizeOptions, 'value', [...new Set(optimizeAttrs.slice(0, -1).split(','))].toString())
        }
      } else {
        this.$set(this.optimizeOptions, 'value', null)
      }
    },
    fieldGroupAttrsParse (item) {
      let optimizeAttrs = ''
      if (item.columnType === 'fieldGroup') {
        item.children.forEach((children) => {
          optimizeAttrs += this.fieldGroupAttrsParse(children)
        })
      } else {
        optimizeAttrs += item.field + ','
      }

      return optimizeAttrs
    },
    querySearch (value) {
      this.searchModel = value
    },
    // сохранить фильтр и вид таблицы
    saveFilterModelAndStateTable () {
      this.isSaveFilter = !this.isSaveFilter
      let columnState = this.$refs.table.$refs.tableBody.$refs.grid.columnApi.getColumnState()
      let savedPivotMode = this.$refs.table.$refs.tableBody.$refs.grid.columnApi.isPivotMode()
      this.$emit('change-property', { name: 'isPivotMode', value: savedPivotMode })
      this.$emit('change-property', { name: 'initialColumnState', value: columnState })
      this.columnFilter = this.$refs.table.$refs.tableBody.$refs.grid.getFilterModel()
    },
    async getCardId (recordId = null) {
      let url = `${this.$config.api}/registryservice/registry/${this.registryId}/card`
      if (recordId) {
        url = `${this.$config.api}/registryservice/registry/${this.registryId}/records/${recordId}/card`
      }
      let data = await this.$http.get(url)
      return data.data[0]
    },
    async openCard (recordId = null) {
      let card = {}
      let preventUserCard = false
      if (this.defaultCardId.cardId) {
        preventUserCard = true
        card = {
          id: this.defaultCardId.cardId
        }
      } else {
        card = await this.getCardId(recordId)
      }
      if (card) {
        let initialData = {}
        if (!recordId) initialData = this.initialDataForCard()
        if (this.isOpenInContainer) {
          this.openRegistryCardInContainer(card, initialData, recordId, preventUserCard)
        } else {
          this.openRegistryCard({
            registryId: this.registryId,
            cardId: card.id,
            cardName: card.name || '',
            recordId: recordId,
            initialData: initialData,
            registry: this.$refs.table,
            readOnly: this.$refs.table.readonly,
            preventUserCard: preventUserCard
          })
        }
      }
    },
    getDefaultsForCard () {
      let defaults = []
      if (this.defaults) {
        this.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }
      return defaults
    },
    async editRecord (selectedRows) {
      this.loading = true
      console.log('selectedRows', selectedRows)
      if (selectedRows?.id) {
        await this.openCard(selectedRows.id)
      }
      this.loading = false
    },
    initialDataForCard () {
      let initialData = {}
      let defaults = this.getDefaultsForCard()
      defaults.forEach((item) => {
        initialData[item.key] = item.value
      })
      return initialData
    },
    addCSSNewRows (idsNewRows, styleRowsByObj) {
      return (params) => {
        if (params.data?.id && idsNewRows.includes(params.data.id)) {
          return styleRowsByObj
        }
      }
    },
    async getCount (lastPayload = {}) {
      let res = 0
      let currentParams = {}
      // удалить request_type data
      const { request_type, ...payloadWithoutRequesType } = lastPayload
      if (Object.keys(lastPayload).length) {
        currentParams = Object.assign({ 'request_type': 'count' }, payloadWithoutRequesType)
      } else {
        currentParams = { 'request_type': 'count' }
      }
      try {
        await this.$http
          .post(`${this.$config.api}/registryservice/registry/${this.registryId}`, currentParams, { hideNotification: true })
          .then((response) => {
            res = response.data.count
          })
      } catch (error) {
        console.log('ошибка в получении количества записей')
        throw error
      }

      return res
    },

    // Вставить запись по таймеру
    async insertValues () {
      const gridApi = this.$refs.table?.$refs.tableBody?.$refs.grid?.gridApi
      if (!gridApi) return
      const gridOptions = this.$refs.table.$refs.tableBody.$refs.grid.gridOptions
      const lastPayload = this.$refs.table.getPayload() || {}
      let newCountRows = 0
      let newDataWithFilter = null
      newCountRows = await this.getCount(lastPayload)
      let styleRows = this.autoRefresh.css
      if (newCountRows <= this.saveLastCount) {
        return
      }
      let limitNewRows = newCountRows - this.saveLastCount
      if (limitNewRows === 0) {
        return
      }
      // удалить сортировку в запросе
      const { order, ...payloadWithoutOrder } = lastPayload
      try {
        newDataWithFilter = (await this.$http.post(`${this.$config.api}/registryservice/registry/${this.registryId}`,
          { ...payloadWithoutOrder, limit: limitNewRows || 1 }, { hideNotification: true })).data.data
      } catch (error) {
        console.log('timer error get data registryservice')
      }
      if (!Array.isArray(newDataWithFilter)) {
        return
      }
      this.saveLastCount = newCountRows
      if (styleRows) {
        const idsNewRows = newDataWithFilter.map(item => item.id)
        const styleArray = styleRows.split(';').map(pair => pair.replace(/\n/g, '').trim())
        const styleObject = {}
        styleArray.forEach(pair => {
          const [key, value] = pair.split(': ')

          styleObject[key] = value
        })
        gridOptions.getRowStyle = this.addCSSNewRows(idsNewRows, styleObject)
      }

      const transaction = {
        addIndex: 0,
        add: newDataWithFilter
      }
      const result = gridApi.applyServerSideTransaction(transaction)
      console.log({ result })
    },
    addRecord () {
      let card = {}
      let preventUserCard = false
      if (this.defaultCardId.cardId) {
        preventUserCard = true
        card = {
          id: this.defaultCardId.cardId
        }
      } else {
        this.openCard()
        return
      }
      let initialData = this.initialDataForCard()

      if (this.isFastCard) {
        this.openQuickAddCardByCheckbox(null, initialData)
      } else {
        if (this.isOpenInContainer) {
          this.openRegistryCardInContainer(card, initialData, null, preventUserCard)
        } else {
          if (this.defaultCardId.isWindow) {
            const h = this.$createElement
            let customClass = 'custom_scrollbar '
            if (this.defaultCardId.windowWidth) {
              customClass += `dashboard_window_width_${this.defaultCardId.windowWidth}`
            }
            let me = this
            this.$msgbox({
              title: me.defaultCardId.windowTitle,
              customClass: customClass,
              message: h('registry-card', {
                style: {
                  height: me.defaultCardId.windowHeight
                },
                props: {
                  cardId: card.id,
                  registryId: me.registryId,
                  recordId: null,
                  initialData: initialData
                },
                on: {
                  cancelChanges: function () {
                    me.$msgbox.close()
                  },
                  recordAdded: function () {
                    me.$refs.table.addRecord()
                  }
                },
                key: this.generateGuid()
              }),
              showCancelButton: false,
              showConfirmButton: false,
              closeOnClickModal: false
            })
          } else {
            this.openRegistryCard({
              registryId: this.registryId,
              cardId: card.id,
              cardName: card.name,
              recordId: null,
              initialData: initialData,
              preventUserCard: preventUserCard,
              registry: {
                addRecord: (recordId) => {
                  this.$refs.table.addRecord(recordId)
                },
                updateRecord: (recordId) => {
                  this.$refs.table.updateRecord(recordId)
                }
              }
            })
          }
        }
      }
    },
    openQuickAddCardByCheckbox (quickAddCard, initialData = {}) {
      const h = this.$createElement
      let customClass = 'custom_scrollbar '
      if (this.defaultCardId.windowWidth) {
        customClass += `window_width_${this.defaultCardId.windowWidth}`
      }
      const me = this
      this.$msgbox({
        customClass: customClass,
        message: h('registry-card', {
          style: {
            height: this.defaultCardId?.windowHeight || ''
          },
          props: {
            cardId: this.defaultCardId.cardId,
            registryId: me.registryId,
            parentContext: null,
            model: {},
            quick: true,
            initialData: initialData
          },
          on: {
            'quick-add': async data => {
              const cardFast = await me.getFastCard(data, me.registryId)
              me.openRegistryCard({
                registryId: me.registryId,
                cardId: cardFast.id,
                cardName: cardFast.name,
                recordId: null,
                initialData: data,
                registry: {
                  addRecord: (recordId) => {
                    this.$refs.table.addRecord(recordId)
                  },
                  updateRecord (recordId) {
                    this.$refs.table.updateRecord(recordId)
                  }
                }
              })
              me.$msgbox.close()
            },
            cancelChanges () {
              me.$msgbox.close()
            }
          },
          key: me.generateGuid()
        }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: false
      })
    },
    async getFastCard (recordData = null, registryId) {
      let data = await this.$http.post(`${this.$config.api}/registryservice/registry/${registryId}/card`,
        recordData, { hideNotification: true })
      return data.data[0]
    },
    openRegistryCardInContainer (card, initialData, recordId = null, preventUserCard = false) {
      // Открыть в контейнере
      const store = this.getContainersStore()
      let registryId = this.registryId
      if (store) {
        // Получить контейнер по алиасу
        const container = store.getByAlias(this.defaultCardId.containerAlias)
        if (container) {
          if (!container.hasOwnProperty('properties') || Array.isArray(container.properties)) {
            this.$set(container, 'properties', {})
          }
          this.$set(container.properties, 'registry', { registryId })
          this.$set(container.properties, 'contentType', 'registry')

          this.$nextTick(() => {
            this.$nextTick(() => {
              this.$nextTick(() => {
                this.$nextTick(() => {
                  const containerRegistry = this.getInterfaceWrapper().$refs[`registry_${container.guid}`]
                  containerRegistry.openRegistryCard({
                    registryId,
                    cardId: card.id,
                    cardName: '',
                    recordId: recordId,
                    initialData,
                    readOnly: false,
                    preventUserCard: preventUserCard,
                    registry: {
                      addRecord () {
                      },
                      updateRecord () {
                      },
                      cancelChanges () {
                      }
                    }
                  })
                })
              })
            })
          })
        }
      }
    },
    async startAutoRefresh () {
      if (this.autoRefresh?.isAllow && this.autoRefresh.seconds >= 5 && !this.isEditor()) {
        let seconds = this.autoRefresh.seconds * 1000
        const lastPayload = this.$refs.table.getPayload() || {}
        this.saveLastCount = await this.getCount(lastPayload)
        try {
          while (!this.componentDestroyed) {
            await this.insertValues()
            await new Promise(resolve => setTimeout(resolve, seconds))
          }
        } catch (error) {
          console.error('Ошибка во время обновления:', error)
        }
      }
    }
  },
  mounted () {
    for (const el in this.showButtonAgGrid) {
      if (this.showButtonAgGrid[el] === true) {
        this.$set(this.visibleToolbarButtons[el], 'isHidden', { type: 'always', condition_type: 'and', conditions: [] })
        this.$set(this.showButtonAgGrid, el, false)
      }
    }
    setTimeout(() => {
      this.startAutoRefresh()
    }, 10000)
  },
  beforeDestroy () {
    this.componentDestroyed = true
    console.log('beforeDestroy componentDestroyed', this.componentDestroyed)
  },

  computed: {
    visibleGridButtons () {
      let res = {}
      for (const el in this.visibleToolbarButtons) {
        res[el] = this.checkConditions(this.visibleToolbarButtons[el].isHidden)
      }
      return res
    },
    dataFilters () {
      console.log(this.filters)
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.registry
      )

      const filters = builder.buildAsApiQl()
      // let filters = []
      // if (this.filters) {
      //   this.filters.forEach((item) => {
      //     let object = {}
      //     let equalsType = 'eq'
      //     if (item.isXref) {
      //       equalsType = 'equals_any'
      //     }
      //     object[equalsType] = {}
      //     if (!item.alias) {
      //       return
      //     }
      //     let alias = item.isXref ? `${item.alias}id` : item.alias
      //     if (!item.type || item.type === 'field') {
      //       if (this.getModel()[item.attribute]) {
      //         object[equalsType][alias] = this.getModel()[item.attribute]
      //       }
      //     } else if (item.type === 'constant') {
      //       object[equalsType][alias] = item.attribute
      //     } else if (item.type === 'current_user') {
      //       object[equalsType][alias] = this.$store.getters['Authorization/userId']
      //     }
      //     if (Object.keys(object[equalsType]).length > 0) {
      //       filters.push(object)
      //     }
      //   })
      // }
      if (this.searchModel && this.searchAttributes) {
        // Проверка настройки поиска - "и/или"
        // replace(/\s+/g, '%') - заменить пробел на %
        let isParametrAnd = this.searchOrByAnd === 'and'
        let getSearcModel = isParametrAnd ? this.searchModel.trim() : this.searchModel.trim().replace(/\s+/g, '%')
        const orX = this.searchAttributes.split(',').reduce((acc, item) => {
          acc.or.push({ like: { [item.trim()]: `%${getSearcModel}%` } })

          return acc
        }, { or: [] })

        filters.push(orX)
      }

      return filters
    },
    key () {
      return `registry_${this.registryId}`
    },
    getFilterModel () {
      if (this.isGridReady) {
        return this.$refs.table.$refs.tableBody.$refs.grid.getFilterModel()
      }

      return {}
    },
    isOpenInContainer () {
      return this.defaultCardId.hasOwnProperty('containerAlias') && this.defaultCardId.containerAlias
    }
  }
}
</script>

<style scoped>

</style>
